import { Box, Center, Text, VStack } from '@chakra-ui/react';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import tableTheme from '@/theme/components/table';

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { useRowSelect } from '@table-library/react-table-library/select';
import { TPagination } from '@/services/api';
import LoadingPage from '@/components/templates/LoadingPage';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import colors from '@/theme/colors';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

interface Props<TData, TSortingColumn> {
  dataWithId: TData;
  tableListItems: { id: string; cells: (string | React.JSX.Element)[] }[];
  columns: readonly TSortingColumn[];
  sortingColumn?: TSortingColumn;
  sortingDirection?: 'asc' | 'desc';
  updateSortingColumn?: (_: TSortingColumn) => void;
  columnsToSortBy?: readonly TSortingColumn[];
  pagination?: TPagination;
  isLoading: boolean;
  isRefetching: boolean;
  isSearching?: boolean;
  noItemsMessage?: string;
  invalidatedItemId?: string;
  hasRowSelect?: boolean;
  tableWidthPercentage?: string;
  fullWidth?: boolean;
}

const CustomTable = <TData extends Array<any>, TSortingColumn extends string>({
  dataWithId,
  tableListItems,
  columns,
  sortingColumn,
  sortingDirection,
  updateSortingColumn,
  columnsToSortBy = columns,
  pagination,
  isLoading,
  isRefetching,
  isSearching,
  noItemsMessage: noItemsMessageParam = 'No Items',
  invalidatedItemId,
  hasRowSelect,
  tableWidthPercentage = '90%',
  fullWidth = false,
}: Props<TData, TSortingColumn>) => {
  const navigate = useNavigate();

  const theme = useTheme(tableTheme);

  const data = {
    nodes: dataWithId,
  };

  const onSelectChange = (action: { payload?: { id: string } }) => {
    const itemId = action.payload?.id;
    navigate({ to: `${location.pathname}/${itemId}` });
  };

  const select = useRowSelect(
    { nodes: [] },
    {
      onChange: onSelectChange,
    },
    { isCarryForward: false },
  );

  const noItemsInAnyPage =
    (tableListItems.length === 0 && !pagination?.totalPages && !isRefetching && !isLoading) ||
    (isRefetching && tableListItems.length === 0);

  useEffect(() => {
    if (tableListItems.length === 0 && pagination?.totalPages && pagination.totalPages > 1 && !isRefetching) {
      if (pagination.currentPage > 1) {
        pagination.getPreviousPage();
      } else {
        pagination.getNextPage();
      }
    }
  }, [tableListItems.length, isRefetching]);

  if (isLoading) return <LoadingPage />;

  return (
    <Box marginTop={'20px'} marginBottom={'80px'} width={fullWidth ? '100%' : tableWidthPercentage}>
      {/* TODO: should the width of the container Box be ={'90%'} ?? */}
      <Table data={data} theme={theme} select={hasRowSelect ? select : undefined}>
        {/* necessary to have the header and columns */}
        {(_: any) => (
          <>
            <Header>
              <HeaderRow>
                {columns.map((column) => {
                  const isSortable = sortingColumn && columnsToSortBy?.includes(column);
                  return (
                    <HeaderCell key={column} className={noItemsInAnyPage ? 'table-no-data-header' : ''}>
                      <Text
                        fontWeight={sortingColumn === column ? 900 : 'bold'}
                        cursor={isSortable ? 'pointer' : 'default'}
                        _hover={{ fontWeight: isSortable ? 900 : 700 }}
                        onClick={() => (isSortable ? updateSortingColumn?.(column) : {})}
                      >
                        {column.toUpperCase()}{' '}
                        {sortingColumn === column && sortingDirection === 'desc' && (
                          <ChevronDownIcon boxSize={'5'} color={colors.text.mediumGray} marginBottom={'3px'} />
                        )}
                        {sortingColumn === column && sortingDirection === 'asc' && (
                          <ChevronUpIcon boxSize={'5'} color={colors.text.mediumGray} marginBottom={'3px'} />
                        )}
                      </Text>
                    </HeaderCell>
                  );
                })}
              </HeaderRow>
            </Header>

            {noItemsInAnyPage && (
              <caption>
                <VStack
                  position={'absolute'}
                  backgroundColor={'white'}
                  top={'80px'} // to not animate the table header
                  height={'50%'}
                  width={'93%'}
                  zIndex={1}
                >
                  <Text variant={'loraSmallTitle'} marginTop={'20px'}>
                    {noItemsMessageParam}
                  </Text>
                </VStack>
              </caption>
            )}

            {isSearching && (
              <caption>
                <VStack
                  position={'absolute'}
                  backgroundColor={'white'}
                  top={'70px'} // to not animate the table header
                  height={'100%'}
                  width={'100%'}
                  zIndex={1}
                >
                  <Center>
                    <LoadingPage width="100%" height="70%" />
                  </Center>
                </VStack>
              </caption>
            )}

            <Body>
              {tableListItems.map((listItem) => (
                <Row
                  key={listItem.id}
                  item={{ id: listItem.id }}
                  className={invalidatedItemId && invalidatedItemId === listItem.id ? 'tr-invalidated' : ''}
                >
                  {listItem.cells.map((cell, i) => (
                    <Cell key={`${i}-${cell.toString()}`}>{cell}</Cell>
                  ))}
                </Row>
              ))}
            </Body>
          </>
        )}
      </Table>
      {data.nodes.length > 0 && pagination && (
        <CompactTablePagination pagination={pagination} isFetchingNextPage={isRefetching} />
      )}
    </Box>
  );
};

export default CustomTable;
