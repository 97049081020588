import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { Center, HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { MEMBER_CARE_FILTER_BY_OPTIONS } from '@/constants/stringVars';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import SearchWithDropdownFilter from '@/components/molecules/SearchWithDropdownFilter';
import { TMemberCare, TMemberCareFilterOption, TMemberCareInvite } from '@/types/MemberCare.types';
import { useInviteMemberCareToPlatform, useMemberCareSpecialists } from '@/hooks/member-care/memberCare';
import CustomTable from '@/components/molecules/CustomTable';
import { getLastLoginStringDisplay } from '@/utils/stringUtils';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL_REGEX } from '@/constants/stringVars';
import * as yup from 'yup';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import iconsPng from '@/assets/img/png/icons';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';
import CustomButton from '@/components/atoms/CustomButton';
import { capitalize } from 'lodash';

const COLUMNS = ['First Name', 'Last Name', 'Email', 'Last Sign In', 'Invitation Status'];
const COLUMNS_TO_SORT_MEMBER_CARES_BY = ['Email', 'Last Sign In', 'Invitation Status'] as const;
export type TMemberCaresSortingColumn = (typeof COLUMNS_TO_SORT_MEMBER_CARES_BY)[number];

const inviteMemberCareSchema = yup
  .object()
  .shape(
    {
      firstName: yup.string().when('firstName', {
        is: (val: string) => val?.length > 0,
        then: () => yup.string().matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed'),
        otherwise: () => yup.string().nullable(),
      }),
      lastName: yup.string().when('lastName', {
        is: (val: string) => val?.length > 0,
        then: () => yup.string().matches(/^[a-zA-Z ]+$/, 'Only alphabetic characters allowed'),
        otherwise: () => yup.string().nullable(),
      }),
      email: yup.string().required('Email is required.').matches(EMAIL_REGEX, 'Wrong email format'),
    },
    [
      ['firstName', 'firstName'],
      ['lastName', 'lastName'],
    ],
  )
  .required();

const MemberCareSpecialists = () => {
  const [filterOption, setFilterOption] = useState<TMemberCareFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const [isMemberCareInviteModalOpened, setIsMemberCareInviteModalOpened] = useState(false);

  const {
    register,
    getValues,
    reset: resetInviteMemberCareForm,
    formState,
  } = useForm<TMemberCareInvite>({
    resolver: yupResolver(inviteMemberCareSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const { isValid, errors } = formState;

  const { sortingColumn, sortingDirection, updateSortingColumn } = useTableColumnSort<string>({
    defaultSortingColumn: 'Last Sign In',
  });

  const {
    isLoading,
    data: memberCareSpecialists,
    pagination,
    isRefetching,
    refetch,
  } = useMemberCareSpecialists({ filterOption, filterText, sortingColumn, sortingDirection });

  const isSearching = useMemo(
    () => filterOption !== undefined && filterText.length >= MIN_CHARS_TO_DO_SEARCH && isRefetching,
    [filterOption, filterText, isRefetching],
  );

  const data = {
    nodes:
      memberCareSpecialists?.map((memberCareSpecialist) => ({
        ...memberCareSpecialist,
        id: memberCareSpecialist.user_id,
      })) ?? [],
  };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.user_id,
          cells: [
            item.user_metadata?.firstName ?? item.firstName ?? '',
            item.user_metadata?.lastName ?? item.lastName ?? '',
            item.email,
            getLastLoginStringDisplay(item.last_login),
            capitalize(item.invitation_status),
          ],
        };
      }),
    [data.nodes],
  );

  const { isPending, mutate: inviteMemberCare } = useInviteMemberCareToPlatform(() => {
    refetch();
    resetInviteMemberCareForm();
    setIsMemberCareInviteModalOpened(false);
  });

  const inviteMemberCareToPlatform = () => {
    const memberCareToInvite = getValues();
    inviteMemberCare(memberCareToInvite);
  };

  return (
    <SidebarItemViewTemplate
      title={'Member Support Specialists'}
      buttonLabel="Invite Member Support"
      onButtonClick={() => setIsMemberCareInviteModalOpened(true)}
    >
      <HStack width={'90%'} marginTop={'20px'} justifyContent={'flex-end'}>
        <SearchWithDropdownFilter
          filterOption={filterOption}
          filterOptions={MEMBER_CARE_FILTER_BY_OPTIONS}
          filterText={filterText}
          setFilterOption={setFilterOption}
          setFilterText={setFilterText}
          isLoadingSearch={isSearching}
        />
      </HStack>
      <CustomTable<TMemberCare[], string>
        columns={COLUMNS}
        columnsToSortBy={COLUMNS_TO_SORT_MEMBER_CARES_BY}
        dataWithId={data.nodes}
        isLoading={isLoading}
        isRefetching={isRefetching}
        isSearching={isSearching}
        pagination={pagination}
        tableListItems={tableListItems}
        noItemsMessage="No Member Support Specialists"
        sortingColumn={sortingColumn}
        updateSortingColumn={updateSortingColumn}
        sortingDirection={sortingDirection}
        hasRowSelect
      />
      <ModalContainer
        isOpen={isMemberCareInviteModalOpened}
        onClose={() => setIsMemberCareInviteModalOpened(false)}
        width={'40%'}
        height={'20vh'}
      >
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
          Invite a Member Support Specialist
        </Text>
        <Center marginTop={'20px'}>
          <Image alt="add-member" src={iconsPng.addMember} width={120} height={120} />
        </Center>
        <Center>
          <VStack marginY={'40px'} width={FORM_FIELD_WIDTH} gap={5}>
            <Input
              {...register('email', { required: true })}
              className="custom-input"
              placeholder={'Email Address'}
              type="email"
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
            />
            {errors.email && <Text variant={'error'}>{errors.email.message}</Text>}
            <Input
              {...register('firstName', {
                required: true,
              })}
              className="custom-input"
              placeholder={'First Name'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
            />
            {errors.firstName && <Text variant={'error'}>{errors.firstName.message}</Text>}
            <Input
              {...register('lastName', {
                required: true,
              })}
              className="custom-input"
              placeholder={'Last Name'}
              _placeholder={{
                fontWeight: '600',
                color: 'background.blueGray',
              }}
            />
            {errors.lastName && <Text variant={'error'}>{errors.lastName.message}</Text>}
            <CustomButton
              style={{ marginTop: '20px' }}
              backgroundColor="secondary.500"
              label="Invite Member Support Specialist"
              width={FORM_FIELD_WIDTH}
              labelColor="white"
              onClick={inviteMemberCareToPlatform}
              disabled={!isValid}
              isLoading={isPending}
            />
          </VStack>
        </Center>
      </ModalContainer>
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/member-care/')({
  component: MemberCareSpecialists,
});
