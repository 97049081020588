import { TIMELINE_DATE_TEXT_DIMENSION, TIMELINE_ICON_DIMENSION, TIMELINE_ITEMS_GAP } from '@/constants/dimensions';
import { TTimeline } from '@/types/Timeline.types';
import getTimelineIconFromType from '@/utils/getTimelineIconFromType';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import React from 'react';

interface Props {
  firstName: string;
  memberTimelineItems: TTimeline[];
}

const MemberCareMemberWellnessTimeline = ({ firstName, memberTimelineItems }: Props) => {
  return (
    <VStack marginTop={'20px'}>
      <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'} marginBottom={'20px'}>
        {firstName}'s Wellness Timeline
      </Text>
      <VStack justifyContent={'flex-start'} gap={'40px'}>
        {memberTimelineItems.map((timelineItem, i) => {
          const isScreenerOrAssessment = timelineItem.type === 'screener' || timelineItem.type === 'assessment';
          const totalScore = timelineItem.data.totalScore * 10;

          const contentDisplay = () => {
            if (isScreenerOrAssessment) {
              return (
                <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                  Took the screener
                </Text>
              );
            }

            if (
              timelineItem.type === 'memberCareNote' ||
              timelineItem.type === 'selfNote' ||
              timelineItem.type === 'observation'
            ) {
              return (
                <VStack gap={0}>
                  <HStack gap={'4px'} width={'100%'} justifyContent={'flex-start'}>
                    {timelineItem.type === 'memberCareNote' || timelineItem.type === 'observation' ? (
                      <>
                        <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                          {timelineItem.type === 'memberCareNote' ? 'Note' : 'Observation'} from
                        </Text>
                        <Text variant={'urbanistSemiBold'} color="primary.500">
                          {timelineItem.data.memberCare?.firstName ?? 'Member Specialist'}
                          {timelineItem.data.memberCare?.firstName ? ' ' : ''}
                          {timelineItem.data.memberCare?.lastName}:
                        </Text>
                      </>
                    ) : (
                      <Text variant={'urbanistSemiBold'} color="text.mediumBlue">
                        Note to yourself:
                      </Text>
                    )}
                  </HStack>
                  <Text
                    variant={'loraItalic'}
                    color={'text.mediumBlue'}
                    maxWidth={'650px'}
                    textAlign={'start'}
                    width={'100%'}
                  >
                    {timelineItem.data.content}
                  </Text>
                </VStack>
              );
            }

            return timelineItem.data.content;
          };

          return (
            <HStack position={'relative'} width={'100%'} gap={`${TIMELINE_ITEMS_GAP}px`} key={timelineItem.id}>
              <Text variant={'urbanistSemiBold'} color={'text.mediumBlue'} width={`${TIMELINE_DATE_TEXT_DIMENSION}px`}>
                {formatDate(new Date(timelineItem.createdAt), 'dd. MMM')}
              </Text>
              <Center
                width={TIMELINE_ICON_DIMENSION}
                height={TIMELINE_ICON_DIMENSION}
                backgroundColor={'extra.white'}
                borderRadius={'full'}
                boxShadow={'0px 3.18px 15.88px 0px #00417933'}
                zIndex={2}
              >
                {getTimelineIconFromType(timelineItem.type)}
              </Center>
              <HStack gap={'4px'}>
                {contentDisplay()}
                {isScreenerOrAssessment && (
                  <Text variant={'urbanistSemiBold'} color={'primary.500'}>
                    Wellness Score - {totalScore.toFixed()}.
                  </Text>
                )}
              </HStack>
              {i < memberTimelineItems.length - 1 && (
                <Box
                  position={'absolute'}
                  top={TIMELINE_ICON_DIMENSION}
                  left={`${TIMELINE_DATE_TEXT_DIMENSION + TIMELINE_ITEMS_GAP + TIMELINE_ICON_DIMENSION / 2 - 1}px`}
                  height={'40px'}
                  width={'2px'}
                  backgroundColor={'#CBDEED'}
                  zIndex={2}
                ></Box>
              )}
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
};

export default MemberCareMemberWellnessTimeline;
