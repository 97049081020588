import {
  TMemberCare,
  TMemberCareFilter,
  TMemberCareFilterOption,
  TMemberCareInvite,
  TMemberCareOrgAssignment,
  TMemberCareMember,
  TMemberCareContactDetailsWithoutEmail,
  TMemberCareMemberNoteType,
} from '@/types/MemberCare.types';
import { usePaginatedSearch } from '../usePaginatedSearch';
import queryKeys from '@/constants/queryKeys';
import {
  reactivateMemberCarePost,
  assignOrgToMemberCarePost,
  getAllMemberCareSpecialists,
  getMemberCareSpecialistById,
  inviteMemberCareToPlatformPost,
  unassignOrgFromMemberCareDelete,
  deactivateMemberCarePost,
  getMemberCareUnassignedOrganizations,
  getMemberCareMembers,
  getMemberCareSpecialistsForOrganizations,
  getMemberCareMemberById,
  updateMemberCareProfileBio,
  updateMemberCareProfilePhoto,
  updateMemberCareProfileContactDetails,
} from '@/services/api/requests/member-care';
import { TOrganization } from '@/types/Organization.types';
import { TCustomAxiosError } from '@/services/api';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePagination } from '../usePagination';
import { TFilterParams, TSortingParams } from '@/types';
import { useAppStore } from '@/store/useAppStore';
import useToast from '../useToast';
import {
  createObservationForMemberCareMember,
  deleteObservation,
  editObservation,
  getObservationsForMemberCareMember,
} from '@/services/api/requests/observations';
import {
  createNoteForMemberCareMember,
  deleteNote,
  editNote,
  getNotesForMemberCareMember,
} from '@/services/api/requests/notes';
import { getTimelineForMemberCareMember } from '@/services/api/requests/timeline';

const useMemberCareSpecialists = ({
  filterOption,
  filterText,
  sortingColumn,
  sortingDirection,
}: TMemberCareFilter & TSortingParams) => {
  return usePaginatedSearch<TMemberCare[], TMemberCareFilterOption, string>({
    query: getAllMemberCareSpecialists,
    queryKey: queryKeys.memberCare.memberCareSpecialists,
    searchFilterType: filterOption ?? 'Email',
    searchFilterText: filterText,
    sortingColumn,
    sortingDirection,
  });
};

const useMemberCareProfile = (memberCareId: TMemberCare['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getMemberCareSpecialistById(memberCareId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useInviteMemberCareToPlatform = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareToInvite: TMemberCareInvite) => {
      const response = await inviteMemberCareToPlatformPost(memberCareToInvite);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.inviteToPlatform] });

      toast({
        status: 'success',
        title: 'Success',
        description: 'Invitation sent successfully!',
      });

      onSuccessCallback?.();

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

const useMemberCareUnassignedOrganizations = (
  memberCareId: TMemberCare['user_id'],
  // { sortingColumn, sortingDirection }: TSortingParams, // TODO: probably should add sorting later
) => {
  return usePagination<TOrganization[], string>({
    query: getMemberCareUnassignedOrganizations,
    queryKey: queryKeys.memberCare.unassignedOrgs,
    // sortingColumn,
    // sortingDirection,
    queryParams: memberCareId,
  });
};

const useAssignOrgToMemberCare = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, TMemberCareOrgAssignment, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (mcOrgAssignment: TMemberCareOrgAssignment) => {
      const response = await assignOrgToMemberCarePost(mcOrgAssignment);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully assigned.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Assigning organization error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUnassignOrgFromMemberCare = (
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<any, TCustomAxiosError, TMemberCareOrgAssignment, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (mcOrgAssignment: TMemberCareOrgAssignment) => {
      const response = await unassignOrgFromMemberCareDelete(mcOrgAssignment);
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully unassigned.',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'Assigning organization error',
        description: error?.errors[0]?.message,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useReactivateMemberCare = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareId: string) => {
      await reactivateMemberCarePost(memberCareId);

      return memberCareId;
    },
    onSuccess: (memberCareId: string) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Support reactivation successful!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

const useDeactivateMemberCare = (onSuccessCallback?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutation({
    mutationFn: async (memberCareId: string) => {
      await deactivateMemberCarePost(memberCareId);

      return memberCareId;
    },
    onSuccess: (memberCareId: string) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.memberCare.memberCareSpecialistById, memberCareId] });

      onSuccessCallback?.();

      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Support deactivation successful!',
      });

      return;
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Error',
        description: error.errors[0].message || error?.toString(),
        status: 'error',
      });
    },
  });

  return { isPending, mutate };
};

// const useMemberCareMembersWithSearch = ({ filterOption, filterText }: TMemberCareMemberFilter) => {
//   return usePaginatedSearch<TMemberCareMember[], TMemberCareMemberFilterOption, string>({
//     query: getMemberCareMembers,
//     queryKey: queryKeys.memberCare.memberCareSpecialists,
//     searchFilterType: filterOption ?? 'First name',
//     searchFilterText: filterText,
//   });
// };

const useMemberCareMembers = ({
  sortingColumn,
  sortingDirection,
  fromDate,
  toDate,
  scoreFilter,
  searchText,
  searchBy,
}: TSortingParams & TFilterParams) => {
  return usePagination<TMemberCareMember[], TFilterParams>({
    query: getMemberCareMembers,
    queryKey: queryKeys.memberCare.memberCareSpecialists,
    sortingColumn,
    sortingDirection,
    queryParams: {
      fromDate,
      toDate,
      scoreFilter,
      searchText,
      searchBy,
    },
  });
};

const useMemberCareSpecialistsForOrganizations = (orgId: string) => {
  return usePagination<TMemberCare[], { orgId: string }>({
    query: getMemberCareSpecialistsForOrganizations,
    queryKey: queryKeys.organization.memberCareSpecialists,
    queryParams: { orgId },
  });
};

const useMemberCareMemberProfile = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberById, memberId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getMemberCareMemberById(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useMemberCareMemberTimeline = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberTimeline, memberId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getTimelineForMemberCareMember(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

// Notes
const useMemberCareMemberNotes = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberNotes, memberId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getNotesForMemberCareMember(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useEditMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { noteId: string; newContent: string }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ noteId, newContent }: { noteId: string; newContent: string }) => {
      editNote(noteId, newContent);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note edited successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note edit error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useDeleteMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, string, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (noteId: string) => {
      deleteNote(noteId);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note deleted successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note deletion error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useCreateMemberCareMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<
    void,
    TCustomAxiosError,
    { content: string; createdFor: string; type: TMemberCareMemberNoteType },
    unknown
  >;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({
      content,
      createdFor,
      type,
    }: {
      content: string;
      createdFor: string;
      type: TMemberCareMemberNoteType;
    }) => {
      createNoteForMemberCareMember(content, createdFor, type);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note created successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note creation error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

// Observations
const useMemberCareMemberObservations = (memberId: TMemberCareMember['user_id']) => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.memberCare.memberCaresMemberObservations, memberId],
    staleTime: 0,
    queryFn: async () => {
      const response = await getObservationsForMemberCareMember(memberId);

      return response;
    },
  });

  return { isLoading, data: data?.data, isError, error, isRefetching, refetch };
};

const useEditMemberObservation = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { observationId: string; newContent: string }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ observationId, newContent }: { observationId: string; newContent: string }) => {
      editObservation(observationId, newContent);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Observation edited successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Observation edit error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useDeleteMemberObservation = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, string, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (observationId: string) => {
      deleteObservation(observationId);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Observation deleted successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Observation deletion error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useCreateMemberCareMemberObservation = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { content: string; memberId: string }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ content, memberId }: { content: string; memberId: string }) => {
      createObservationForMemberCareMember(content, memberId);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Observation created successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Observation creation error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUpdateMemberCareProfilePhoto = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, File, unknown>;
} => {
  const toast = useToast();
  const { memberCare, setMemberCare } = useAppStore();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (newProfilePhoto: File) => {
      if (!memberCare) throw new Error('No Member Support data');

      const updatedMemberCare = await updateMemberCareProfilePhoto(memberCare, newProfilePhoto);
      setMemberCare(updatedMemberCare);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Profile Photo updated successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Support Profile photo error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUpdateMemberCareContactDetails = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, TMemberCareContactDetailsWithoutEmail, unknown>;
} => {
  const toast = useToast();
  const { setMemberCare } = useAppStore();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (newMemberCareContactDetails: TMemberCareContactDetailsWithoutEmail) => {
      const updatedMemberCare = await updateMemberCareProfileContactDetails(newMemberCareContactDetails);
      setMemberCare(updatedMemberCare);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Support Contact Details updated successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Support Contact Details error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useUpdateMemberCareBio = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, string, unknown>;
} => {
  const toast = useToast();
  const { memberCare, setMemberCare } = useAppStore();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (newBio: string) => {
      if (!memberCare) throw new Error('No Member Support data');

      const updatedMemberCare = await updateMemberCareProfileBio(memberCare, newBio);
      setMemberCare(updatedMemberCare);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Support Bio updated successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Support Bio error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

export {
  useMemberCareSpecialists,
  useMemberCareProfile,
  useInviteMemberCareToPlatform,
  useMemberCareUnassignedOrganizations,
  useAssignOrgToMemberCare,
  useUnassignOrgFromMemberCare,
  useReactivateMemberCare,
  useDeactivateMemberCare,
  useMemberCareMembers,
  useMemberCareSpecialistsForOrganizations,
  useMemberCareMemberProfile,
  useMemberCareMemberTimeline,
  useMemberCareMemberNotes,
  useEditMemberNote,
  useDeleteMemberNote,
  useCreateMemberCareMemberNote,
  useMemberCareMemberObservations,
  useDeleteMemberObservation,
  useEditMemberObservation,
  useCreateMemberCareMemberObservation,
  useUpdateMemberCareProfilePhoto,
  useUpdateMemberCareContactDetails,
  useUpdateMemberCareBio,
};
