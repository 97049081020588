import { HStack } from '@chakra-ui/react';
import React from 'react';
import CustomButton from '../CustomButton';

export type TTabButtonOption = {
  onClick: () => void;
  labelValue: string;
  labelDisplay: string;
};

interface Props {
  options: TTabButtonOption[];
  selectedOptionValue: string;
}

const TabButtons = ({ options, selectedOptionValue }: Props) => {
  return (
    <HStack
      backgroundColor={'white'}
      padding={'8px'}
      borderRadius={'8px'}
      gap={'10px'}
      boxShadow={'0px 2px 2px 0px #7C9EBC80'}
    >
      {options.map((option) => (
        <CustomButton
          key={option.labelDisplay}
          label={option.labelDisplay}
          onClick={option.onClick}
          backgroundColor={selectedOptionValue === option.labelValue ? 'primary.500' : 'white'}
          labelColor={selectedOptionValue === option.labelValue ? 'white' : 'primary.500'}
          isTransparent={selectedOptionValue !== option.labelValue}
        />
      ))}
    </HStack>
  );
};

export default TabButtons;
