import CircleFillOutWithText from '@/components/molecules/WellnessStateCircle/CircleFillOutWithText';
import DateInput from '@/components/atoms/DateInput';
import Dropdown from '@/components/atoms/Dropdown';
import CustomTable from '@/components/molecules/CustomTable';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import {
  MEMBER_CARE_SCORE_FILTER_FE_BE,
  MEMBER_CARE_SCORE_FILTER_LABELS,
  MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL,
  MEMBER_CARE_SEARCH_BY_LABELS,
} from '@/constants/stringVars';
import { useMemberCareMembers } from '@/hooks/member-care/memberCare';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import { TMemberCareScoreFilterLabelFE, TMemberCareMember, TMemberCareSearchByLabelFE } from '@/types/MemberCare.types';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';
import { Center, HStack, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { isBefore } from 'date-fns';
import { useMemo, useState } from 'react';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';

const COLUMNS = ['Member Name', 'Organization', 'Last Activity', 'Score'] as const;
export type TMemberCareMembersSortingColumn = (typeof COLUMNS)[number];

const LAST_ACTIVITY_DATE_INIT = '';
const SCORE_FILTER_INIT = 'All Scores';

const SEARCH_TEXT_INIT = '';
const SEARCH_BY_INIT = '';

const MemberCareMembers = () => {
  const { sortingColumn, sortingDirection, updateSortingColumn } = useTableColumnSort<TMemberCareMembersSortingColumn>({
    defaultSortingColumn: 'Score',
  });

  const [localLastActivityFromDate, setLocalLastActivityFromDate] = useState(LAST_ACTIVITY_DATE_INIT);
  const [localLastActivityToDate, setLocalLastActivityToDate] = useState(LAST_ACTIVITY_DATE_INIT);
  const [localScoreFilter, setLocalScoreFilter] = useState<TMemberCareScoreFilterLabelFE>(SCORE_FILTER_INIT);

  const [committedLastActivityFromDate, setCommittedLastActivityFromDate] = useState(LAST_ACTIVITY_DATE_INIT);
  const [committedLastActivityToDate, setCommittedLastActivityToDate] = useState(LAST_ACTIVITY_DATE_INIT);
  const [committedScoreFilter, setCommittedScoreFilter] = useState<TMemberCareScoreFilterLabelFE>(SCORE_FILTER_INIT);

  const filterIsActive = Boolean(
    committedLastActivityFromDate || committedLastActivityToDate || committedScoreFilter !== SCORE_FILTER_INIT,
  );

  // Updates all the 'committed' states which are sent to the data-fetching hook `useMemberCareMembers`
  const submitLocalFilterForm = () => {
    setCommittedLastActivityFromDate(localLastActivityFromDate);
    setCommittedLastActivityToDate(localLastActivityToDate);
    setCommittedScoreFilter(localScoreFilter);

    pagination.getFirstPage();
  };

  const resetFilterForm = () => {
    setLocalLastActivityFromDate(LAST_ACTIVITY_DATE_INIT);
    setLocalLastActivityToDate(LAST_ACTIVITY_DATE_INIT);
    setLocalScoreFilter(SCORE_FILTER_INIT);

    setCommittedLastActivityFromDate(LAST_ACTIVITY_DATE_INIT);
    setCommittedLastActivityToDate(LAST_ACTIVITY_DATE_INIT);
    setCommittedScoreFilter(SCORE_FILTER_INIT);

    pagination.getFirstPage();
  };

  const [localSearchText, setLocalSearchText] = useState(SEARCH_TEXT_INIT);
  const [localSearchBy, setLocalSearchBy] = useState<TMemberCareSearchByLabelFE>(SEARCH_BY_INIT);

  const [committedSearchText, setCommittedSearchText] = useState(SEARCH_TEXT_INIT);
  const [committedSearchBy, setCommittedSearchBy] = useState<TMemberCareSearchByLabelFE>(SEARCH_BY_INIT);

  const searchIsActive = committedSearchBy !== '' && committedSearchText.length > 0;

  // Updates all the 'committed' states which are sent to the data-fetching hook `useMemberCareMembers`
  const submitLocalSearchForm = () => {
    setCommittedSearchText(localSearchText);
    setCommittedSearchBy(localSearchBy);

    pagination.getFirstPage();
  };

  const resetSearchForm = () => {
    setLocalSearchText(SEARCH_TEXT_INIT);
    setLocalSearchBy(SEARCH_BY_INIT);

    setCommittedSearchText(SEARCH_TEXT_INIT);
    setCommittedSearchBy(SEARCH_BY_INIT);

    pagination.getFirstPage();
  };

  const {
    data: members,
    pagination,
    isLoading,
    isRefetching,
  } = useMemberCareMembers({
    sortingColumn,
    sortingDirection,
    fromDate: committedLastActivityFromDate,
    toDate: committedLastActivityToDate,
    scoreFilter: committedScoreFilter,
    searchText: committedSearchText,
    searchBy: committedSearchBy,
  });

  const membersDataWithId = members ? members.map((member) => ({ ...member, id: member.user_id })) : [];

  const tableListItems = useMemo(
    () =>
      membersDataWithId.map((memberCareMember: TMemberCareMember) => {
        let lastActivityLabel = 'No Activity';
        if (memberCareMember.assessmentSubmittedAt) {
          const [year, month, date] = memberCareMember.assessmentSubmittedAt.split('T')[0].split('-');
          lastActivityLabel = `${month}/${date}/${year}`;
        }

        // in case old/deprecated data score is larger then 10, we need to multiply it by 10
        // Should not show up on production, but just in case
        const totalScoreX10 =
          memberCareMember?.totalScore > 10 ? memberCareMember?.totalScore : memberCareMember?.totalScore * 10;

        return {
          id: memberCareMember.user_id,
          cells: [
            `${memberCareMember.firstName} ${memberCareMember.lastName}`,
            memberCareMember.orgName ?? memberCareMember.organizationName ?? '',
            lastActivityLabel,
            !memberCareMember?.totalScore ? (
              <Text variant={'urbanistSmallBold'} color="text.mediumGray">
                NO SCORE
              </Text>
            ) : (
              <HStack gap={'14px'} marginLeft={'5px'}>
                <CircleFillOutWithText
                  circleDiameter={25}
                  svgId={memberCareMember.user_id}
                  thickness={4}
                  percentageFilled={totalScoreX10}
                  circleColor={getColorFromPercentage(totalScoreX10)}
                  showPercentageText={false}
                  boxShadow={'0px 3px 6px 0px #0E006224'}
                />
                <Text variant={'urbanistSemiBold'}>{totalScoreX10.toFixed()}</Text>
              </HStack>
            ),
          ],
        };
      }),
    [membersDataWithId],
  );

  const filterSubmitButtonDisabled =
    localLastActivityFromDate === committedLastActivityFromDate &&
    localLastActivityToDate === committedLastActivityToDate &&
    localScoreFilter === committedScoreFilter;

  const searchSubmitButtonDisabled =
    !localSearchBy ||
    localSearchText.length < MIN_CHARS_TO_DO_SEARCH ||
    (localSearchBy === committedSearchBy && localSearchText === committedSearchText);

  const scoreFilterNumberRangeDisplay = useMemo(() => {
    if (localScoreFilter !== 'All Scores') {
      const scoreDataRange = MEMBER_CARE_SCORE_FILTER_FE_BE[MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL[localScoreFilter]];

      if (scoreDataRange) {
        return (
          <Text variant={'urbanistBold'}>
            {scoreDataRange.fromScore * 10} to {scoreDataRange.toScore * 10}
          </Text>
        );
      }
    }
    return '';
  }, [localScoreFilter]);

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH}>
        <HStack justifyContent={'space-between'} width={'100%'}>
          <Text variant="loraTitle">My Members</Text>
          <HStack>
            {/* Filter by Date/Score */}
            <FilterSelectPopover
              onFilterSubmit={submitLocalFilterForm}
              onFilterReset={resetFilterForm}
              submitButtonDisabled={filterSubmitButtonDisabled}
              isFilterActive={filterIsActive}
              filterIconStyle={{ marginTop: '3px' }}
              popoverContent={
                <VStack marginBottom={'24px'} alignItems={'start'}>
                  <Text variant={'urbanistBold'} marginY={'10px'}>
                    Last Activity
                  </Text>
                  <HStack gap={'1rem'}>
                    <DateInput
                      label="From"
                      value={localLastActivityFromDate}
                      updateValue={(value) => {
                        setLocalLastActivityFromDate(value);
                        if (
                          localLastActivityToDate.length &&
                          isBefore(new Date(localLastActivityToDate), new Date(value))
                        ) {
                          setLocalLastActivityToDate(value);
                        }
                      }}
                    />
                    <DateInput
                      label="To"
                      value={localLastActivityToDate}
                      minValue={localLastActivityFromDate}
                      updateValue={(value) => setLocalLastActivityToDate(value)}
                    />
                  </HStack>
                  <HStack width={'100%'} justifyContent={'space-between'} marginY={'10px'}>
                    <Text variant={'urbanistBold'}>Score</Text>
                    {scoreFilterNumberRangeDisplay}
                  </HStack>
                  <Dropdown
                    options={MEMBER_CARE_SCORE_FILTER_LABELS}
                    title={committedScoreFilter}
                    selectedOption={localScoreFilter}
                    defaultOption={committedScoreFilter === 'All Scores' ? 'All Scores' : ''}
                    popoverWidth="320px"
                    width="100%"
                    height="54px"
                    onOptionClick={(option) => setLocalScoreFilter(option)}
                  />
                </VStack>
              }
            />

            {/* Search by Employee/Organization */}
            <FilterSelectPopover
              filterIconPath={svgIconPaths.magnifyingGlass}
              filterIconText="Search"
              contentWidth="260px"
              onFilterSubmit={submitLocalSearchForm}
              onFilterReset={resetSearchForm}
              submitButtonDisabled={searchSubmitButtonDisabled}
              isFilterActive={searchIsActive}
              popoverContent={
                <VStack marginBottom={'24px'} alignItems={'start'} width={'100%'}>
                  <Text variant={'urbanistBold'} marginTop={'10px'}>
                    Search By
                  </Text>
                  <Dropdown
                    options={MEMBER_CARE_SEARCH_BY_LABELS}
                    title="Choose"
                    selectedOption={localSearchBy}
                    defaultOption={'Choose'}
                    popoverWidth="240px"
                    width="100%"
                    height="54px"
                    onOptionClick={(option) => setLocalSearchBy(option)}
                  />

                  <InputGroup marginTop={'10px'} flexDir={'column'}>
                    <InputLeftElement>
                      <Center height={'100%'} marginTop={'18px'}>
                        <SvgIcon
                          iconPath={svgIconPaths.magnifyingGlass}
                          color={localSearchText.length > 0 ? colors.text.darkBlue : colors.text.mediumGray}
                        />
                      </Center>
                    </InputLeftElement>
                    <Input
                      className="custom-input"
                      backgroundColor={'white'}
                      placeholder="Search..."
                      _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      value={localSearchText}
                      onChange={(e) => setLocalSearchText(e.target.value)}
                      _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
                    />
                    <Text variant={'urbanistSmallBold'} color={'text.mediumGray'} padding={'5px'}>
                      Minimum 3 characters
                    </Text>
                  </InputGroup>
                </VStack>
              }
            />
          </HStack>
        </HStack>

        <CustomTable<TMemberCareMember[], TMemberCareMembersSortingColumn>
          isLoading={isLoading}
          dataWithId={membersDataWithId}
          isRefetching={isRefetching}
          pagination={pagination}
          tableListItems={tableListItems}
          isSearching={false}
          noItemsMessage="No members"
          columns={COLUMNS}
          sortingColumn={sortingColumn}
          updateSortingColumn={updateSortingColumn}
          sortingDirection={sortingDirection}
          tableWidthPercentage="100%"
          hasRowSelect
        />
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/')({
  component: MemberCareMembers,
});
