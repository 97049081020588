import TopRightBackgroundCircle from '@/components/atoms/TopRightBackgroundCircle';
import ProfileBox from '@/components/molecules/ProfileBox';
import UserProfileBoxInfoPiece from '@/components/atoms/UserProfileBoxInfoPiece';
import UserProfilePhotoBox from '@/components/molecules/UserProfilePhotoBox';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import LoadingPage from '@/components/templates/LoadingPage';
import { memberCareRoutes } from '@/constants/routes/app';
import { useUpdateMemberCareProfilePhoto } from '@/hooks/member-care/memberCare';
import { useAppStore } from '@/store/useAppStore';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';

const MemberCareProfile = () => {
  const navigate = useNavigate({ from: memberCareRoutes.memberCareProfile });

  const { memberCare } = useAppStore();

  const onClickEditContactDetails = () => {
    navigate({ to: '/member-care/profile/contact' });
  };

  const onClickEditBio = () => {
    navigate({ to: '/member-care/profile/bio' });
  };

  const { mutate: updateMemberCareProfilePhoto, isPending } = useUpdateMemberCareProfilePhoto();

  const [profilePhotoFile, setProfilePhotoFile] = useState<File | null>(null);

  console.log('memberCare', memberCare);
  useEffect(() => {
    if (profilePhotoFile) {
      updateMemberCareProfilePhoto(profilePhotoFile);
    }
  }, [profilePhotoFile]);

  const contactBoxItems = useMemo(
    () => [
      { title: 'EMAIL ADDRESS', text: memberCare?.email ?? '' },
      {
        title: 'NAME',
        text: `${memberCare?.firstName ?? memberCare?.user_metadata?.firstName} ${memberCare?.lastName ?? memberCare?.user_metadata?.lastName}`,
      },
      { title: 'PHONE NUMBER', text: memberCare?.phone ?? memberCare?.user_metadata?.phone ?? '' },
      {
        title: 'CALENDLY LINK',
        text: memberCare?.user_metadata?.calendly_link ?? '/',
        isLink: !!memberCare?.user_metadata?.calendly_link,
      },
      { title: 'SEX', text: memberCare?.sex ?? memberCare?.user_metadata?.sex ?? '' },
    ],
    [memberCare],
  );

  if (!memberCare) return <LoadingPage />;

  return (
    <AppPageTemplate>
      <TopRightBackgroundCircle />
      <Text variant={'loraSmallTitle'}>Your Member Support Profile</Text>
      <Box marginTop={'80px'}>
        <UserProfilePhotoBox
          setProfilePhoto={setProfilePhotoFile}
          initialPreviewUrl={memberCare.user_metadata?.profilePicture ?? memberCare.user_metadata?.profile_photo}
          isLoading={isPending}
        />
      </Box>

      <HStack width={'100%'} justifyContent={'space-between'} marginTop={'40px'} gap={'40px'}>
        <ProfileBox
          style={{ height: '360px', paddingTop: '30px' }}
          title="Contact Details"
          onClickEdit={onClickEditContactDetails}
        >
          <VStack alignItems={'start'} gap={'15px'} marginTop={'10px'}>
            {contactBoxItems.map((item) => (
              <UserProfileBoxInfoPiece key={item.title} title={item.title} text={item.text} isLink={item.isLink} />
            ))}
          </VStack>
        </ProfileBox>
        <ProfileBox style={{ height: '360px', paddingTop: '30px' }} title="Bio" onClickEdit={onClickEditBio}>
          <Text
            variant={'urbanistSemiBold'}
            textAlign={'left'}
            overflowY={'scroll'}
            maxHeight={'220px'}
            marginTop={'20px'}
            whiteSpace={'pre-wrap'}
          >
            {memberCare?.user_metadata?.bio?.trim()}
          </Text>
        </ProfileBox>
      </HStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/profile/')({
  component: MemberCareProfile,
});
