import React, { CSSProperties, PropsWithChildren } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, HStack, Flex } from '@chakra-ui/react';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';

export type TModalContainerProps = {
  isOpen: boolean;
  title?: string;
  footerContent?: React.ReactNode;
  onClose?: () => void;
  isCentered?: boolean;
  width?: string;
  height?: string;
  contentStyle?: CSSProperties;
};

const ModalContainer = ({
  isOpen = false,
  children,
  title,
  footerContent,
  onClose = () => {},
  isCentered = true,
  width = '50%',
  height = '50vh',
  contentStyle,
}: PropsWithChildren<TModalContainerProps>) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay>
        <ModalContent backgroundColor={'#F8FCFF'} minWidth={width} minHeight={height} style={contentStyle}>
          <HStack justifyContent={'space-between'} position={'relative'}>
            <Flex width={'100%'}>
              <ModalHeader style={{ textAlign: 'center', minWidth: '100%' }}>{title}</ModalHeader>
            </Flex>
            <Flex
              position={'absolute'}
              right={0}
              padding={'12px'}
              borderRadius={'4px'}
              cursor={'pointer'}
              _hover={{ backgroundColor: 'background.cloudGray' }}
              onClick={onClose}
            >
              <SvgIcon iconPath={svgIconPaths.close} color={'primary.black'} size="16" />
            </Flex>
          </HStack>
          <ModalBody>{children}</ModalBody>
          {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default ModalContainer;
