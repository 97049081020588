import { svgIconPaths, svgIcons } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import TabButtons, { TTabButtonOption } from '@/components/atoms/TabButtons';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import LoadingPage from '@/components/templates/LoadingPage';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { MEMBER_PROFILE_SECTIONS } from '@/constants/stringVars';
import { useMemberCareMemberProfile, useMemberCareMemberTimeline } from '@/hooks/member-care/memberCare';
import MemberCareMemberNotes from '@/modules/memberCare/MemberCareMemberNotes';
import MemberCareMemberObservations from '@/modules/memberCare/MemberCareMemberObservations';
import MemberCareMemberWellnessTimeline from '@/modules/memberCare/MemberCareMemberWellnessTimeline';
import colors from '@/theme/colors';
import { TMemberProfileSection } from '@/types/MemberCare.types';
import { getAgeFromBirthDay } from '@/utils/stringUtils';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';

const IMAGE_SIZE_PX = '80px';

const MemberProfileView = () => {
  const { memberCareMemberId } = Route.useParams();

  const [selectedProfileSection, setSelectedProfileSection] = useState<TMemberProfileSection>('member');

  const { data: member, isLoading } = useMemberCareMemberProfile(memberCareMemberId);
  const { data: memberTimelineItems, isLoading: isLoadingMemberDocuments } =
    useMemberCareMemberTimeline(memberCareMemberId);

  const memberProfileSectionTabs: TTabButtonOption[] = MEMBER_PROFILE_SECTIONS.map((section) => ({
    labelDisplay: section.display,
    labelValue: section.value,
    onClick: () => setSelectedProfileSection(section.value),
  }));

  if (isLoading || isLoadingMemberDocuments || !member || !memberTimelineItems) return <LoadingPage />;

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH} paddingBottom={'100px'}>
        {/* Actual page content */}
        <VStack width={'100%'} marginBottom={'50px'}>
          <HStack justify={'space-between'} width={'100%'} marginBottom={'50px'}>
            {/* User photo and info */}
            <HStack gap={'20px'}>
              <Image
                src={member.profilePicture || svgIcons.genericUser}
                width={IMAGE_SIZE_PX}
                height={IMAGE_SIZE_PX}
                alt="member-profile-photo"
                borderRadius={'50%'}
                objectFit={'cover'}
              />
              <VStack gap={'2px'} alignItems={'flex-start'}>
                <Text variant="loraSmallTitle" fontWeight={'500'}>
                  {member.firstName} {member.lastName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {member.orgName || member.organizationName}
                </Text>
                <Text variant={'urbanistSemiBold'} color={'text.mediumGray'}>
                  {member.dateOfBirth &&
                    getAgeFromBirthDay(member.dateOfBirth) > 0 &&
                    `${getAgeFromBirthDay(member.dateOfBirth)} years old`}{' '}
                  {member.sex}
                </Text>
              </VStack>
            </HStack>

            {/* Chat and Call buttons */}
            <HStack gap={8}>
              <CustomButton
                onClick={() => {}} // TODO: Example nav  ->  navigate({ to: '/something/$somethingId', params: { somethingId: 'id-of-something' } })
                isTransparent
                label="Chat"
                labelColor={colors.text.darkBlue}
                iconBeforeLabelSvgPath={svgIconPaths.message}
                iconBeforeLabelSvgColor={colors.text.darkBlue}
                hoverBackgroundColor="extra.white"
                disabled
              />
              <CustomButton
                backgroundColor="primary.500"
                onClick={() => alert('Requesting . . .')}
                label="Call"
                labelColor={colors.text.darkBlue}
                iconBeforeLabelSvgPath={svgIconPaths.phone}
                iconBeforeLabelSvgColor={colors.text.darkBlue}
                disabled
              />
            </HStack>
          </HStack>

          <VStack marginY={'30px'}>
            <TabButtons options={memberProfileSectionTabs} selectedOptionValue={selectedProfileSection} />
          </VStack>

          <VStack display={selectedProfileSection === 'member' ? 'flex' : 'none'}>
            <Text variant="loraSmallTitle" color="text.mediumGray" fontWeight={'500'}>
              {member.firstName}'s Wellness Report
            </Text>
            <WellnessStateCircle
              wellnessData={member.healthDomainScores}
              userAvatar={member.profilePicture}
              averageScore={member.averageScore}
            />
            <MemberCareMemberWellnessTimeline firstName={member.firstName} memberTimelineItems={memberTimelineItems} />
          </VStack>

          <HStack
            marginTop={'40px'}
            gap={'60px'}
            display={selectedProfileSection === 'care-plan-and-notes' ? 'flex' : 'none'}
            alignItems={'start'}
          >
            <MemberCareMemberNotes memberId={memberCareMemberId} memberFirstName={member.firstName} />
            <MemberCareMemberObservations memberId={memberCareMemberId} />
          </HStack>
        </VStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/$memberCareMemberId')({
  component: MemberProfileView,
});
